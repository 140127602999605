:root {
  --blue-color: rgb(16, 60, 85);
  --red-color: rgb(253, 106, 94);
  --grey-color: rgb(117, 117, 117);
  --yellow-color: #3799d0;
  --yellow-light-color: #fff5dd;
  --grey-light-color: #F4F3F4;
  --green-dark-color: #0b8686;
  --green-button: #00871A;
  --blue-button: #0a92de;
  --green-light-color: #0fb1b1;
  --green-footer-color: #035555;
  --blue-footer-color: #103c55;
  --blue-light-color: rgb(49, 144, 203);
  --blue-grey-color: rgb(87, 128, 167);
  --default-color: rgb(37, 37, 37);
  --default-color-blue: rgb(17, 53, 75);
  --default-shadow: 0px 0px 30px 0px rgb(0 0 0 / 75%);
  --blue-shadow: 0px 10px 10px 4px rgba(49, 144, 203, 0.25);
  --blue-shadow-little: 0px 2px 4px 2px rgba(49, 144, 203, 0.25);
  --orange-color: rgb(196, 116, 46);
  --font-red-hat-display: 'Red Hat Display', sans-serif;
}

@font-face {
  font-family: 'Red Hat Display';
  src: url(/_next/static/media/RedHatDisplay-Light.da212cdb.eot);
  src: url(/_next/static/media/RedHatDisplay-Light.da212cdb.eot?#iefix) format('embedded-opentype'),
    url(/_next/static/media/RedHatDisplay-Light.183c2ef9.woff2) format('woff2'),
    url(/_next/static/media/RedHatDisplay-Light.76f7dc50.woff) format('woff'),
    url(/_next/static/media/RedHatDisplay-Light.0eb8f57d.ttf) format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Red Hat Display';
  src: url(/_next/static/media/RedHatDisplay-ExtraBoldItalic.f15c1cf4.eot);
  src: url(/_next/static/media/RedHatDisplay-ExtraBoldItalic.f15c1cf4.eot?#iefix) format('embedded-opentype'),
    url(/_next/static/media/RedHatDisplay-ExtraBoldItalic.ccca13e2.woff2) format('woff2'),
    url(/_next/static/media/RedHatDisplay-ExtraBoldItalic.ed3aadf5.woff) format('woff'),
    url(/_next/static/media/RedHatDisplay-ExtraBoldItalic.f1d0109a.ttf) format('truetype');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Red Hat Display';
  src: url(/_next/static/media/RedHatDisplay-BlackItalic.2cad0cb6.eot);
  src: url(/_next/static/media/RedHatDisplay-BlackItalic.2cad0cb6.eot?#iefix) format('embedded-opentype'),
    url(/_next/static/media/RedHatDisplay-BlackItalic.d6da9109.woff2) format('woff2'),
    url(/_next/static/media/RedHatDisplay-BlackItalic.78190748.woff) format('woff'),
    url(/_next/static/media/RedHatDisplay-BlackItalic.897015af.ttf) format('truetype');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Red Hat Display';
  src: url(/_next/static/media/RedHatDisplay-Bold.9783f68f.eot);
  src: url(/_next/static/media/RedHatDisplay-Bold.9783f68f.eot?#iefix) format('embedded-opentype'),
    url(/_next/static/media/RedHatDisplay-Bold.0dccfe94.woff2) format('woff2'),
    url(/_next/static/media/RedHatDisplay-Bold.b17eb82d.woff) format('woff'),
    url(/_next/static/media/RedHatDisplay-Bold.5a8fd84a.ttf) format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Red Hat Display';
  src: url(/_next/static/media/RedHatDisplay-BoldItalic.49d95532.eot);
  src: url(/_next/static/media/RedHatDisplay-BoldItalic.49d95532.eot?#iefix) format('embedded-opentype'),
    url(/_next/static/media/RedHatDisplay-BoldItalic.ea85c5db.woff2) format('woff2'),
    url(/_next/static/media/RedHatDisplay-BoldItalic.7738e14d.woff) format('woff'),
    url(/_next/static/media/RedHatDisplay-BoldItalic.43627ada.ttf) format('truetype');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Red Hat Display';
  src: url(/_next/static/media/RedHatDisplay-LightItalic.c5128f9a.eot);
  src: url(/_next/static/media/RedHatDisplay-LightItalic.c5128f9a.eot?#iefix) format('embedded-opentype'),
    url(/_next/static/media/RedHatDisplay-LightItalic.739aa96d.woff2) format('woff2'),
    url(/_next/static/media/RedHatDisplay-LightItalic.837e0e7e.woff) format('woff'),
    url(/_next/static/media/RedHatDisplay-LightItalic.e127cbb9.ttf) format('truetype');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Red Hat Display';
  src: url(/_next/static/media/RedHatDisplay-Italic.d922d3a6.eot);
  src: url(/_next/static/media/RedHatDisplay-Italic.d922d3a6.eot?#iefix) format('embedded-opentype'),
    url(/_next/static/media/RedHatDisplay-Italic.115618f7.woff2) format('woff2'),
    url(/_next/static/media/RedHatDisplay-Italic.9a75c790.woff) format('woff'),
    url(/_next/static/media/RedHatDisplay-Italic.c49a2968.ttf) format('truetype');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Red Hat Display';
  src: url(/_next/static/media/RedHatDisplay-Black.d105d9f1.eot);
  src: url(/_next/static/media/RedHatDisplay-Black.d105d9f1.eot?#iefix) format('embedded-opentype'),
    url(/_next/static/media/RedHatDisplay-Black.2099dfee.woff2) format('woff2'),
    url(/_next/static/media/RedHatDisplay-Black.d1a7ea11.woff) format('woff'),
    url(/_next/static/media/RedHatDisplay-Black.7e8e1ba9.ttf) format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Red Hat Display';
  src: url(/_next/static/media/RedHatDisplay-ExtraBold.302182e0.eot);
  src: url(/_next/static/media/RedHatDisplay-ExtraBold.302182e0.eot?#iefix) format('embedded-opentype'),
    url(/_next/static/media/RedHatDisplay-ExtraBold.ceac1b00.woff2) format('woff2'),
    url(/_next/static/media/RedHatDisplay-ExtraBold.a28f6056.woff) format('woff'),
    url(/_next/static/media/RedHatDisplay-ExtraBold.ae05d335.ttf) format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Red Hat Display';
  src: url(/_next/static/media/RedHatDisplay-Medium.7671abd9.eot);
  src: url(/_next/static/media/RedHatDisplay-Medium.7671abd9.eot?#iefix) format('embedded-opentype'),
    url(/_next/static/media/RedHatDisplay-Medium.d65204f6.woff2) format('woff2'),
    url(/_next/static/media/RedHatDisplay-Medium.7c5daba2.woff) format('woff'),
    url(/_next/static/media/RedHatDisplay-Medium.ab314ff6.ttf) format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Red Hat Display';
  src: url(/_next/static/media/RedHatDisplay-MediumItalic.73dddac5.eot);
  src: url(/_next/static/media/RedHatDisplay-MediumItalic.73dddac5.eot?#iefix) format('embedded-opentype'),
    url(/_next/static/media/RedHatDisplay-MediumItalic.a6d92b38.woff2) format('woff2'),
    url(/_next/static/media/RedHatDisplay-MediumItalic.af083c31.woff) format('woff'),
    url(/_next/static/media/RedHatDisplay-MediumItalic.bfe80e6d.ttf) format('truetype');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Red Hat Display';
  src: url(/_next/static/media/RedHatDisplay-SemiBold.fd2e65e6.eot);
  src: url(/_next/static/media/RedHatDisplay-SemiBold.fd2e65e6.eot?#iefix) format('embedded-opentype'),
    url(/_next/static/media/RedHatDisplay-SemiBold.af49bbee.woff2) format('woff2'),
    url(/_next/static/media/RedHatDisplay-SemiBold.35eb4aed.woff) format('woff'),
    url(/_next/static/media/RedHatDisplay-SemiBold.32ee68fe.ttf) format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Red Hat Display';
  src: url(/_next/static/media/RedHatDisplay-Regular.884b1f5f.eot);
  src: url(/_next/static/media/RedHatDisplay-Regular.884b1f5f.eot?#iefix) format('embedded-opentype'),
    url(/_next/static/media/RedHatDisplay-Regular.b6346c64.woff2) format('woff2'),
    url(/_next/static/media/RedHatDisplay-Regular.e66379ad.woff) format('woff'),
    url(/_next/static/media/RedHatDisplay-Regular.b0207da4.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Red Hat Display';
  src: url(/_next/static/media/RedHatDisplay-SemiBoldItalic.4d8fc30b.eot);
  src: url(/_next/static/media/RedHatDisplay-SemiBoldItalic.4d8fc30b.eot?#iefix) format('embedded-opentype'),
    url(/_next/static/media/RedHatDisplay-SemiBoldItalic.d41282a3.woff2) format('woff2'),
    url(/_next/static/media/RedHatDisplay-SemiBoldItalic.54b9826f.woff) format('woff'),
    url(/_next/static/media/RedHatDisplay-SemiBoldItalic.8b6ebdf9.ttf) format('truetype');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: var(--font-red-hat-display);
  transition: 0.5s;
  scroll-behavior: smooth;
  max-width: 100vw;
  overflow-x: hidden;
}

footer,
header,
body {
  font-family:  var(--font-red-hat-display);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family:  var(--font-red-hat-display);
}

a {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}

button {
  cursor: pointer;
  background-color: transparent;
  padding: 0;
  border: none;
}

* {
  box-sizing: border-box;
}

.container {
  max-width: 1140px;
  margin: 0 auto;
  width: 100%;
  padding: 0 15px;
}

.container-wider {
  width: 100%;
  /* max-width: 96vw;
    margin-left: 2vw;
    margin-right: 2vw; */
}

.default-title {
  color: var(--default-color-blue);
  font-size: 40px;
  margin-bottom: 50px;
}

.default-subtitle {
  color: var(--default-color);
  font-size: 30px;
  margin-bottom: 30px;
  margin: 0 0 20px;
}

.default-text {
  color: var(--default-color);
  font-size: 20px;
  line-height: 24px;
}

.little-text {
  color: var(--default-color);
  font-size: 18px;
  line-height: 26px;
}

.main-button {
  background-color: var(--green-dark-color);
  border: solid 1px var(--green-dark-color);
  color: #fff;
  padding: 20px 50px;
  border-radius: 30px;
  font-size: 22px;
  transition: 0.5s;
}

.main-button:hover {
  background-color: transparent;
  color: var(--green-dark-color);
}

.main-button.blue-button {
  color: #fff;
  background-color: var(--blue-button);
  border: solid 1px var(--blue-button);
}

.main-button.blue-button:hover {
  background-color: transparent;
  color: var(--blue-button);
  border: solid 1px var(--blue-button);
}

.home-main-banner {
  background-color: #103C55;
}

.home-main-banner .two-col-container-b {
  padding: 150px 70px;
  background-size: 53%;
  background-position: right bottom 150px;
  background-repeat: no-repeat;
}

.main-banner-text {
  /* max-width: 500px; */
  text-shadow: 4px 3px 0px #103c55;
}

.main-banner-text .default-title {
  font-size: 54px;
  line-height: 75px;
  font-weight: bold;
  margin-bottom: 10px;
}

.main-banner-text .default-subtitle {
  font-size: 40px;
  line-height: 58px;
  margin-bottom: 40px;
  font-weight: 300;
  max-width: 400px;
}

.two-col {
  width: 50%;
  padding: 0 15px;
}

img.image-two-col {
  width: 100%;
}

.text-center {
  text-align: center;
}

.color-light-green {
  color: var(--blue-button) !important;
}

/* HEADER */

header {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  background-color: transparent;
  display: flex;
  padding: 20px 70px;
  transition: 0.5s;
}

header.blu-header-bg {
  background-color: var(--blue-footer-color);
}

header.offcanvas-open {
  position: fixed;
  bottom: 0;
  background-color: var(--blue-color);
  display: block;
  z-index: 2;
}

.header-container {
  max-width: 1600px;
  margin: 15px auto;
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  justify-content: space-between;
}

a.header-phone-link {
  color: #fff;
  font-size: 18px;
  border: solid 1px #fff;
  padding: 5px 20px;
  border-radius: 20px;
  transition: 0.5s;
}

a.header-phone-link:hover {
  background-color: #ffffff;
  color: var(--blue-color);
}

a.header-phone-link .header-contact-icon {
  margin-right: 10px;
}

.headerposition a.header-phone-link {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  margin-left: 10px;
  max-width: 180px;
  background-color: rgba(16, 60, 85, 1);
}

.headerposition a.header-phone-link:hover {
  color: rgba(16, 60, 85, 1);
  background-color: rgb(255, 255, 255);
}

.headerposition a.header-phone-link .labelassistenza {
  font-size: 10px;
}

.headerposition a.header-phone-link .labelassistenza b {
  font-weight: normal;
  font-size: 18px;
}

.headerposition a.header-phone-link {
  margin-left: 10px;
}

img.logo {
  max-width: 360px;
}

.header-menu-container {
  flex-grow: 1;
  display: flex;
  align-items: flex-end;
  height: 100%;
  margin-left: 40px;
  position: relative;
}

.header-menu-container .header-link {
  margin-right: 30px;
}

.header-menu-container .header-link:last-child {
  margin-right: 0px;
}

.header-menu-container .header-link,
.header-menu-container .header-link>a,
.header-menu-container .header-link .header_fake_link {
  color: #ffffff;
  font-size: 20px;
  transition: 0.5s;
  position: relative;
  padding-bottom: 10px;
}

.header-menu-container .header-link .header_fake_link {
  padding-bottom: 0;
}

.header-menu-container .header-link.menu-selected>a::after,
.header-menu-container .header-link.menu-selected>div.header_no_link::after {
  position: absolute;
  left: -5px;
  right: -5px;
  bottom: 0px;
  content: " ";
  border-radius: 3px;
  height: 5px;
  background-color: var(--yellow-color);
}

.header-menu-container .header-link>a:hover,
.header-menu-container .header-link .header_fake_link:hover {
  color: var(--yellow-color);
}

.header-link .header-submenu {
  display: block;
  height: 0;
  overflow: hidden;
  position: absolute;
  top: 35px;
  z-index: 1;
  left: -10px;
  opacity: 0;
  transition: all 1s;
  width: 250px;
  background-color: transparent;
  padding: 0;
}

.header-link:hover .header-submenu {
  background-color: var(--blue-color);
  padding: 10px;
  opacity: 1;
  height: initial;
}

.header-link .header-submenu .header-link {
  margin-bottom: 10px;
}

.header-link .header-submenu .header-link a:hover {
  text-decoration: underline;
}

.header-link .header-submenu .header-link:last-child {
  margin-bottom: 0;
}

.header-hamburger {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
}

.header-hamburger .hamburger-button {
  display: none;
}

.header-hamburger .hamburger-button .hamburger-button-inner {
  background-color: #fff;
  height: 1px;
  width: 22px;
  position: relative;
  -webkit-transition: .5s;
  transition: .5s;
  display: block;
}

.header-hamburger .hamburger-button .hamburger-button-inner::before {
  position: absolute;
  right: 0;
  width: 22px;
  top: -9px;
  height: 1px;
  background-color: #fff;
  content: "";
  -webkit-transition: .5s;
  transition: .5s;
}

.header-hamburger .hamburger-button .hamburger-button-inner::after {
  position: absolute;
  right: 0;
  width: 22px;
  bottom: -9px;
  height: 1px;
  background-color: #fff;
  content: "";
  -webkit-transition: .5s;
  transition: .5s;
}

.header-hamburger .hamburger-button .hamburger-button-inner.hamburger-closure {
  background-color: transparent;
}

.header-hamburger .hamburger-button .hamburger-button-inner.hamburger-closure::before {
  -webkit-transform: translate3d(0, 4px, 0) rotate(45deg);
  transform: translate3d(0, 4px, 0) rotate(45deg);
  width: 25px;
  top: -5px;
}

.header-hamburger .hamburger-button .hamburger-button-inner.hamburger-closure::after {
  -webkit-transform: translate3d(0, -4px, 0) rotate(-45deg);
  transform: translate3d(0, -4px, 0) rotate(-45deg);
  width: 25px;
  bottom: -3px;
}

.offcanvas-menu-container {
  text-align: center;
  margin-top: 50px;
}

.offcanvas-menu-container .header-link,
.offcanvas-menu-container .header-link a {
  color: #fff;
  font-size: 24px;
  line-height: 26px;
  margin-bottom: 20px;
}

.offcanvas-menu-container .header-link svg {
  display: none;
}

.offcanvas-menu-container .header-link .header-submenu {
  position: relative;
  height: initial;
  opacity: 1;
  text-align: center;
  left: unset;
  right: unset;
  top: unset;
  bottom: unset;
  width: auto;
}

.offcanvas-menu-container .header-link .header-submenu .header-link a {
  font-size: 20px;
  font-style: italic;
  font-weight: normal;
}

.offcanvas-phone-container {
  margin-top: 50px;
}

/* FINE HEADER */

/* FOOTER */

footer {
  /* background-color: var(--blue-color); */
  background-color: var(--blue-footer-color);
  padding: 80px 0;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.footer-container {
  max-width: 1600px;
  margin: 0 auto;
  display: flex;
}

.footer-text-container {
  width: 40%;
  padding: 0 20px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.footer-block {
  width: 20%;
  padding: 0 20px;
}

.footer-description {
  color: #fff;
  line-height: 30px;
  font-weight: 100;
  font-size: 16px;
  letter-spacing: 0.5px;
}

.footer-title {
  color: #ffffff;
  margin-top: 0;
  margin-bottom: 20px;
  padding-bottom: 20px;
  position: relative;
  letter-spacing: 1px;
  font-size: 20px;
}

.footer-title::after {
  position: absolute;
  content: " ";
  width: 40px;
  height: 4px;
  bottom: 0;
  left: 0;
  background-color: var(--yellow-color);
}

.footer-label-block,
.footer-label-block a {
  color: #fff;
  font-size: 16px;
  line-height: 30px;
  margin-bottom: 10px;
  font-weight: 100;
  letter-spacing: 0.5px;
  cursor: pointer;
}

.footer-label-block:hover,
.footer-label-block a:hover {
  text-decoration: underline;
}

.footer-social {
  margin-top: 30px;
  display: flex;
}

.footer-social a {
  padding: 10px;
  border: solid 1px #fff;
  border-radius: 50%;
  width: 44px;
  text-align: center;
  height: 44px;
  margin-right: 15px;
  transition: 0.5s;
}

.footer-social-icon {
  font-size: 20px;
  color: #fff;
  height:25px;
}

.footer-social a:hover {
  background-color: #fff;
}

.footer-social a:hover .footer-social-icon {
  color: var(--blue-color);
}

.footer-logo-container img {
  max-width: 300px;
  height: auto;
  width: 100%;
  margin-bottom: 20px;
}

/* FINE FOOTER */

.main-banner {
  display: flex;
  align-items: flex-end;
  padding-top: 80px;
  background-color: #7bb9e6;
  background-image: url(/oltre_bg/white_mask.png), url(/oltre_bg/donna_oltre_desktop.png), url(/oltre_bg/left_house.png), url(/oltre_bg/uomo_orologio.png), url(/oltre_bg/right_house.png), url(/oltre_bg/bg_houses.png);
  background-size: 100%, 200px, 25%, 250px, 25%, 55%;
  background-position: bottom center, left 30px bottom 340px, left 150px, right 50px, calc(100% + 30px) 120px, center 280px;
  background-repeat: no-repeat;

}

.main-banner .main-title {
  text-shadow: 0px 2px 8px #4f799b !important;
  max-width: 60%;
}


p.title-subline {
  font-size: 23px;
  line-height: 28px;
  max-width: 970px;
  text-align: center;
  margin: 0 auto 50px auto;
}

div.buttonsubline {
  width: 100%;
  padding-top: 5px;
  text-align: center;
  color: var(--green-button);
  font-weight: bold;
  font-size: 13px;
}

.home-mutual-description {
  font-size: 25px;
  color: #fff;
  margin-top: 15px;
  max-width: 500px;
}

.home-mutual-input-container {
  background-color: transparent;
  width: 100%;
  display: inline-block;
  padding: 0 15px 0 0;
}

button.home-mutual-input-button {
  float: left;
  padding: 8px 40px;
  text-align: center;
  background: #FFFFFF;
  text-transform: uppercase;
  color: #103C55;
  border: solid 1px #103C55;
  border-radius: 3px;
  /* font-size: 22px; */
  font-size: 21px;
  font-weight: bold;
  margin-top: 8px;
  transition: 0.5s;
}

button.home-mutual-input-button:hover {
  color: #fff;
  background-color: #103C55;
  border-color: #fff;
}

.home-mutual-input-container {
  width: 50%;
  /* max-width: 350px; */
}

.home-main-banner .two-col-container-b .default-title {
  max-width: 80%;
}


#estimator-list.header_placeholder {
  width: 100%;
  height: 140px;
}


/* NEW LEAD STEP */
.popup-container.popup-preventivatore .popup-background {
  background: #fff;
}

.popup-preventivatore .popup-content {
  padding: 0;
  border-radius: 3px;
  border: 0px solid #DFE3E8;
  box-shadow: none;
    /* box-shadow: 0px 6px 8px #A4A4A437; */

}

.popup-head {
  /* background: #41BA58; */
  background: #cac9c9;
  /* color: #fff; */
  color: #5c5c5c;
  text-transform: uppercase;
  font-weight: bold;
  line-height: 53px;
  font-size: 22px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
}

/* .popup-preventivatore-head-left{
  box-shadow: none;
} */

.popup-container.popup-preventivatore {
  position: relative;
  padding-top: 50px;
  padding-bottom: 50px;
}

/*
  RIPRISTINO COMPORTAMENTO POPUP PREVENTIVATORE
*/
.popup-container.popup-preventivatore {
  position: fixed;
  padding: 0;
  top: 0px;
  overflow-y: hidden;
  height: 100vh;
}

.popup-container.popup-preventivatore.page-form {
  position: relative;
  padding: 0;
  top: 0px;
  overflow-y: inherit;
  height: auto;
  background:#F4F3F4
}

.popup-container.popup-preventivatore .popup-content {
  height: 100vh;
  overflow-y: hidden;
}

.popup-container.popup-preventivatore.page-form .popup-content {
  overflow-y: inherit;
  height: auto;
  margin-top: 50px;
  margin-bottom: 50px;
}

.popup-container.popup-preventivatore .popup-content .popup-rowcontainer {
  height: calc(100vh - 53px);
  overflow: scroll;
}

.popup-container.popup-preventivatore.page-form .popup-content .popup-rowcontainer {
  overflow-x: hidden;
  overflow-y: inherit;
  height: auto;
  margin-top: 50px;
}

.popup-container.popup-preventivatore.page-form .popup-background {
  position: relative;
}

/*
  FINE RIPRISTINO COMPORTAMENTO POPUP
*/

.popup-rowcontainer {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  /* -webkit-justify-content: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start; */
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
}

.popup-leftCol {
  border-right: 2px dashed #707070;
  margin-top: 30px;
  margin-bottom: 30px;
  padding: 40px;
  text-align: left;
  min-width: 280px;
}

.popup-rightCol {
  /* margin-top: 30px; */
  margin-bottom: 30px;
  padding: 20px;
  width: calc(100% - 607px);
  padding-left: 76px;
  padding-right: 76px;
}

.popup-leftCol img.image-in-estimator-list {
  margin-bottom: 20px;
}

.introForm {
  padding-top: 0px;
  padding-bottom: 65px;
  font-family:  var(--font-red-hat-display);
  font-size: 30px;
  font-weight: normal;
  color: #202124;
  text-align:left;
}

.formContainer .inputPopupForm {
  padding: 0;
}

.error-phone-container .select-prefix-container {
  top: 5px;
}

/*.popup-rightCol .select-prefix-container{
  top:10%;
}*/

.formContainer .inputPopupForm input {
  border-radius: 0;
  border: 1px solid #707070;
  box-shadow: none;
  font-size:24px;
}

.popup-preventivatore-form-container .main-button {
  width: 100%;
  border-radius: 0;
  text-transform: uppercase;
  font-weight: bold;
  box-shadow: 0px 3px 6px #00000029;
  font-family:  var(--font-red-hat-display);
  color: #fff;
  text-transform: uppercase;
  padding: 10px 0px;
  font-size: 25px;
  font-weight: bold;
  line-height: 33px;
  text-align: center;
  order: 0;
  flex: 2 2 auto;
  align-self: stretch;
}

.buttonShowFormClosePreventivo {
  text-decoration: underline;
  cursor: pointer;
}

.sustenibility .introForm {
  padding-top: 0px;
  padding-bottom: 65px;
  font-family:  var(--font-red-hat-display);
  font-size: 25px;
  font-weight: normal;
  color: #202124;
  text-align: left;
}

.popup-content.sustenibility .popup-rowcontainer {
  margin: 0 auto;
}

.nofear{
  color: #767676;
  background: #e3f8f0;
  font-size: 20px;
  text-align: center;
  margin-top: 15px;
  padding: 15px;
  border-radius: 10px;
  font-weight: 400;
  min-width: calc(100% + 150px);
  margin-left: -75px;
  font-size: 17px;
}

/* BLOG PAGES */
.blogSection{}

.blogSection .introHeadingBlog{
  border-bottom: 2px solid var(--blue-button);
}

.blogSection h1{
  text-transform: uppercase;
}

@media(min-width: 1400px) {
  .home-main-banner .two-col-container-b .default-title {
    max-width: 50%;
  }
}

@media(min-width: 1600px) {
  header {
    padding: 20px;
  }

  .home-main-banner .two-col-container-b {
    padding-left: 0;
    padding-right: 0;
  }

  .main-banner {
    background-color: #7bb9e6;
    background-image:
      url(/oltre_bg/white_mask.png),
      url(/oltre_bg/donna_oltre_desktop.png),
      url(/oltre_bg/left_house.png),
      url(/oltre_bg/uomo_orologio.png),
      url(/oltre_bg/right_house.png),
      url(/oltre_bg/bg_houses.png);
    background-size:
      100% 50%,
      200px,
      25%,
      250px,
      25%,
      55%;
    background-position:
      bottom center,
      bottom 340px left calc(50% - 600px),
      left 150px,
      calc(50% + 600px) 50px,
      calc(100% + 30px) 120px,
      center 280px;
    background-repeat: no-repeat;

  }

  .main-title {
    max-width: 1000px !important;
  }
}

@media (min-width: 1597px){
  .popup-container.popup-preventivatore.page-form .popup-content {
    width: 1500px !important;
    max-width: 1500px !important;
  }

  .checkPhone .popup-rightCol {
    width: calc(100%);
    padding-left: 76px;
    padding-right: 76px;
  }

  .popup-content.sustenibility  .popup-rowcontainer{
    width: calc(100%);
      padding-left: 76px;
      padding-right: 76px;
      max-width: 1000px;
      margin: 0 auto;
  }

}


@media (min-width: 1000px) {
  .popup-container.popup-preventivatore.page-form .popup-content.sustenibility {
      display: flex
    }

    /* .popup-container.popup-preventivatore.page-form .popup-content.sustenibility .sust_container{
      width:100%;
    } */

    .popup-container.popup-preventivatore.page-form .popup-content.sustenibility .sust_container .sust_rightCol {
      width: 100%;
      padding-top:0px;
    }


    .popup-container.popup-preventivatore.page-form .popup-content.sustenibility .popup-rowcontainer {
      margin-top: 20px;
      width: 100%;
    }

    .popup-container.popup-preventivatore.page-form .popup-content.sustenibility .popup-rightCol {
      width: 100%;
      padding-top: 0;
      margin-bottom: 0;
    }

    .popup-container.popup-preventivatore.page-form .popup-content.sustenibility .introForm{
        font-size: 30px;
    }


    .popup-content.sustenibility .popup-rowcontainer{
      width: 60%;
      padding-left: 0;
      padding-right: 0;
      padding-top: 0px !important;
    }
}

@media(max-height:820px) and (min-width: 990px) {
  .home-main-banner .two-col-container-b .default-title {
    margin-top: 15px;
    font-size: 50px;
    line-height: 65px;
  }

  .main-banner-text .default-subtitle {
    margin-bottom: 15px;
  }

}

@media(max-width: 1597px){
  .popup-container.popup-preventivatore.page-form .popup-content{
      width: calc(100vw - 30px) !important;
      margin-top: 10px !important;
      margin-bottom: 10px !important;
    }

    .popup-rightCol{
      width: 60%;
      padding-left: 46px;
      padding-right: 46px;
      padding-top: 0 !important;
    }

    .checkPhone .popup-rightCol {
      width: calc(100vw - 30px) !important;
      padding-left:0px;
    }

}

@media(max-width: 1300px){
  .sustenibility .introForm {
      padding-top: 0px;
      padding-bottom: 65px;
      font-family:  var(--font-red-hat-display);
      font-size: 20px;
    }

        .popup-preventivatore-form-container label span.toc-label{
          font-size:12px;
        }
}


@media(max-width: 991px) {
  .main-banner {
    background-color: #7bb9e6;
    background-image:
      url(/oltre_bg/white_mask.png),
      url(/oltre_bg/donna_oltre_desktop.png),
      url(/oltre_bg/left_house.png),
      url(/oltre_bg/uomo_orologio.png),
      url(/oltre_bg/right_house.png),
      url(/oltre_bg/bg_houses.png);
    background-size:
      100% 50%,
      200px,
      25%,
      250px,
      25%,
      55%;
    background-position:
      left bottom,
      left 10px bottom 760px,
      left 150px,
      calc(100% + 40px) 0,
      calc(100% + 30px) 120px,
      center 280px;
    background-repeat: no-repeat;
  }

  .headerposition a.header-phone-link,
  a.header-phone-link {
    background-color: rgba(49, 144, 203, 0.15);
    border-radius: 5px !important;
    color: var(--blue-light-color);
    border: 1px solid var(--blue-light-color);
    padding: 8px 20px !important;
    text-align: center;
    text-transform: uppercase;
    margin: 10px 0 !important;
    font-size: 12px;
    cursor: pointer;
    font-weight: normal;
  }

  .headerposition a.header-phone-link:hover,
  a.header-phone-link:hover {
    background-color: var(--blue-light-color);
    color: #fff;
  }

  .home-mutual-input-container {
    width: 100%;
  }

  .popup-rowcontainer {
    flex-direction: column;
    background: #F4F3F4;
  }

  .popup-rightCol {
    width: min(100%, 600px);
    margin: 0 auto;
    padding: 0 !important;
    padding-bottom: 70px !important;
  }

   .popup-container.popup-preventivatore.page-form .popup-content .popup-rowcontainer{
    margin-top:0;
   }

  .popup-container.popup-preventivatore.page-form{
  margin-top:40px;
  }

  #estimator-list.header_placeholder{
    height: 70px;
  }

  .popup-content.sustenibility .sust_container .sust_rightCol{
    padding-bottom:0;
  }

  .popup-content.sustenibility .popup-rightCol{
    padding-top:40px !important;
  }

  .popup-content.sustenibility .popup-rightCol .introForm.introFormCustom{
    text-align:center;
    /* font-weight:400 */
  }

  .stickyButtonContainer,
  .popup-content.sustenibility .stickyButtonContainer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0 15px;
    z-index: 999999999
  }
    .stickyButtonContainerPositioned button,
  .stickyButtonContainer button,
  .popup-content.sustenibility .stickyButtonContainer button{
    max-width:600px;
    width:100%;
  }
}

@media(max-width: 900px) {
  #cms-page .moduleResultsContainer {
    padding-top: 0;
  }
}

@media(max-width: 767px) {
  .main-banner {
    background-color: #7bb9e6;
    background-image:
      url(/oltre_bg/white_mask.png),
      url(/oltre_bg/donna_oltre_mobile.png),
      url(/oltre_bg/left_house_mobile.png),
      url(/oltre_bg/uomo_orologio.png),
      url(/oltre_bg/right_house_mobile.png),
      url(/oltre_bg/middle_blue_mask.png),
      url(/oltre_bg/middle_houses_mobile.png);
    background-size:
      100% 50%,
      80px,
      100px,
      150px,
      100px,
      100px,
      100%;
    background-position:
      bottom center,
      0 90px,
      0 40px,
      calc(100% + 30px) 30px,
      right 0,
      left 50px,
      left 280px;
    background-repeat: no-repeat,
      no-repeat,
      no-repeat,
      no-repeat,
      no-repeat,
      repeat-x,
      repeat-x;
  }

  .main-banner .main-title {
    max-width: 80%;
  }

  .headerposition a.header-phone-link {
    max-width: 170px;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .headerposition a.header-phone-link .labelassistenza {
    font-size: 10px;
  }

  .headerposition a.header-phone-link .labelassistenza b {
    font-weight: normal;
    font-size: 12px;
  }

  #estimator-list.header_placeholder {
    width: 100%;
    height: 80px;
  }

  #estimator-list.header_placeholder {
    height: 70px;
  }

  .popup-container.popup-preventivatore.page-form {
    margin-top: 30px;
  }

  .nofear {
    min-width: 100%;
    margin-left: 0px;
  }
}

@media(max-width: 600px) {
  img.logo {
    max-width: 280px;
  }
}

@media(max-width: 550px) {}

@media(max-width: 500px) {

  .main-banner {
    background-color: #7bb9e6;
    background-image:
      url(/oltre_bg/white_mask.png),
      url(/oltre_bg/donna_oltre_mobile.png),
      url(/oltre_bg/left_house_mobile.png),
      url(/oltre_bg/uomo_orologio.png),
      url(/oltre_bg/right_house_mobile.png),
      url(/oltre_bg/middle_blue_mask.png),
      url(/oltre_bg/middle_houses_mobile.png);
    background-size:
      100% 50%,
      70px,
      100px,
      150px,
      100px,
      100px,
      150%;
    background-position:
      bottom center,
      0 90px,
      0 40px,
      calc(100% + 60px) 30px,
      right 0,
      left 50px,
      left 280px;
    background-repeat: no-repeat,
      no-repeat,
      no-repeat,
      no-repeat,
      no-repeat,
      repeat-x,
      repeat-x;
  }

  img.logo {
    max-width: 180px;
  }

     #estimator-list.header_placeholder {
       height: 70px;
     }

     .popup-container.popup-preventivatore.page-form{
      margin-top: 0;
     }
}

@media(max-width: 350px) {

  .headerposition a.header-phone-link {
    margin-left: 10px;
  }

  img.logo {
    max-width: 130px;
  }

  .main-button {
    background-color: var(--green-dark-color);
    border: solid 1px var(--green-dark-color);
    color: #fff;
    padding: 20px 12px;
    border-radius: 30px;
    font-size: 22px;
    transition: 0.5s;
  }
}

.main-banner-container {
  max-width: 800px;
  margin: 0;
}

.main-title {
  color: #ffffff;
  font-size: 40px;
  margin-bottom: 30px;
  text-align: center;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;

}

/* CARDS HOME */

.home-counter {
  padding: 40px 35px;
  margin: 0 10px;
  width: 25%;
  transition: 0.5s;
  display: inline-block;
}

.home-counter:hover {
  background-color: #fff;
  border-radius: 100px 5px 100px 5px;
  box-shadow: var(--blue-shadow);
}

.home-card-icon-container {
  font-size: 40px;
  background-color: #000;
  color: #fff;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  align-items: center;
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  background-color: transparent;
}

/* .home-card-icon-container.grey-blue {
  background-color: rgb(112, 150, 172);
}
.home-card-icon-container.light-blue {
  background-color: var(--blue-light-color);
}
.home-card-icon-container.red {
  background-color: var(--red-color);
}
.home-card-icon-container.dark-blue {
  background-color: var(--blue-color);
} */
.home-counter .home-card-icon-container {
  font-size: 25px;
  width: 60px;
  height: 60px;
  float: left;
  /* background-color: var(--blue-light-color); */
  /* background-color: #fff; */
  background-color: transparent;
  margin-bottom: 0;
}

.home-counter .home-counter-text-container {
  float: left;
  max-width: calc(100% - 70px);
  margin-left: 10px;
}

.home-counter-title {
  font-size: 25px;
  font-weight: bold;
  color: var(--yellow-color);
}

.home-counter-text {
  font-weight: bold;
}

/* CONTACT FORM */
.contact-form-container {
  padding: 40px;
  background-color: #fff;
  box-shadow: var(--blue-shadow);
  max-width: 650px;
}

.contact-form-container form {
  margin-top: 30px;
}

.two-input-container {
  width: 100%;
  display: flex;
}

.two-input-container .input-container {
  width: 50%;
  display: block;
  margin-bottom: 30px;
}

.input-container-full {
  margin-bottom: 30px;
  display: block;
}

.input-container-full.phone-input,
.input-container.phone-input {
  position: relative;
}

.two-input-container .input-container.left {
  margin-right: 20px;
}

.two-input-container .input-container.right {
  margin-left: 20px;
}

.two-input-container .input-container label,
.input-container-full label {
  display: block;
  font-size: 18px;
  padding-bottom: 5px;
}

.two-input-container .input-container.error label,
.input-container-full.error label {
  color: var(--red-color);
}

.two-input-container .input-container input,
.input-container-full input,
.two-input-container .input-container select,
.input-container-full select,
.two-input-container .input-container textarea,
.input-container-full textarea {
  display: block;
  width: 100%;
  outline: none;
  border-radius: 30px;
  padding: 10px 15px;
  border: solid 1px;
  font-size: 18px;
}

.two-input-container .input-container select,
.input-container-full select {
  padding-right: 40px;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
}

.input-container-full.phone-input input,
.input-container.phone-input input {
  padding-left: 115px;
}

.two-input-container .input-container.error input,
.input-container-full.error input {
  border-color: var(--red-color);
}

.input-checkbox {
  margin-bottom: 30px;
  max-width: 400px;
}

.input-checkbox label {
  display: block;
  position: relative;
  padding-left: 40px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.input-checkbox.error label {
  color: var(--red-color);
}

.input-checkbox label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 30px;
  width: 30px;
  background-color: #fff;
  border: solid 1px #ccc;
  transition: 0.5s;
  border-radius: 3px;
}

.error .checkmark {
  border-color: var(--red-color);
}

.input-checkbox label input:checked~.checkmark {
  background-color: var(--blue-light-color);
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.input-checkbox label input:checked~.checkmark:after {
  display: block;
}

.input-checkbox label .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.form-error {
  margin-bottom: 30px;
  color: var(--red-color);
}

.select-prefix-container {
  position: absolute;
  top: 30px;
  padding: 7px 0px 7px 15px;
}

/* PREVENTIVATORE */

/* Hide default HTML checkbox */

/* The slider */

/* Rounded sliders */

/* FINE CONTACT FORM */

/* PREVENTIVATORE */

/* .estimator-list-container {
    padding-top: 50px;
} */

.estimator-list-container .default-title {
  text-align: center;
  margin-top: 0;
}

.estimator-list-table {
  font-size: 14px;
  border: 1px solid #DFE3E8;
}

.estimator-list-button {
  width: 100%;
  text-align: center;
  font-weight: bold;
  font-size: 16px;
  color: #fff;
  padding: 15px;
  border-radius: 3px;
  text-transform: uppercase;
  border: solid 1px var(--green-button);
  background-color: var(--green-button);
  transition: 0.5s;
  margin-bottom: 10px;
}

.estimator-list-button:hover {
  background-color: #fff;
  color: var(--green-button);
}

.estimator-list-button.blue-button {
  background-color: var(--blue-light-color);
  border-color: var(--blue-light-color);
  margin-top: 10px;
}

.estimator-list-button.blue-button:hover {
  background-color: #fff;
  color: var(--blue-light-color);
}

img.image-in-estimator-list {
  max-height: 50px;
  max-width: 200px;
  width: auto;
}

.popup-preventivatore .popup-content {
  background-size: auto;
  background-repeat: no-repeat;
  background-position-y: 0;
  background-position-x: center;
  max-width: 100%;
  width: 900px;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  /*    margin-top: 50px;*/
}

.popup-preventivatore-form-container .two-input-container .input-container>input,
.popup-preventivatore-form-container textarea {
  border: none;
  box-shadow: var(--blue-shadow-little);
}

.popup-preventivatore-form-container textarea {
  font-family: inherit;
}

.popup-preventivatore-form-container label {
  font-weight: bold;
  text-align: left;
}

.popup-preventivatore-form-container label span.toc-label{
  font-weight: normal;
}

/* POPUP */

.popup-container {
  position: fixed;
  display: flex;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 20;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.popup-background {
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  cursor: pointer;
}

.popup-content {
  background: #fff;
  padding: 20px;
  z-index: 1;
  text-align: center;
  -webkit-box-shadow: var(--default-shadow);
  -moz-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.75);
  box-shadow: var(--default-shadow);
  -webkit-order: 0;
  -ms-flex-order: 0;
  order: 0;
  -webkit-flex: 0 1 auto;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  align-self: auto;
  max-width: 600px;
  position: relative;
  border-radius: 40px;
}

.popup-close-container {
  position: absolute;
  right: 0;
  top: -40px;
}

.popup-close-icon {
  width: 30px;
  height: 30px;
  background-color: transparent;
  content: "";
  position: relative;
  cursor: pointer;
}

.popup-close-icon::after {
  position: absolute;
  right: 0;
  width: 35px;
  bottom: -15px;
  height: 1px;
  background-color: #fff;
  content: "";
  -webkit-transform: translate3d(0, -4px, 0) rotate(-45deg);
  transform: translate3d(0, -4px, 0) rotate(-45deg);
}

.popup-close-icon::before {
  position: absolute;
  right: 0;
  width: 35px;
  bottom: -8px;
  height: 1px;
  background-color: #fff;
  content: "";
  -webkit-transform: translate3d(0, 4px, 0) rotate(45deg);
  transform: translate3d(0, 4px, 0) rotate(45deg);
}

.popup-title {
  color: var(--default-color-blue);
  font-size: 30px;
  margin-bottom: 30px;
}

img.popup-image {
  max-width: 100%;
  margin-top: 30px;
  height: auto;
  width: 250px;
  margin-bottom: 20px;
}

/* FINE POPUP */

/* SERVICES PAGES */

/* Hide scrollbar for Chrome, Safari and Opera */

/* Hide scrollbar for IE, Edge and Firefox */

/* FINE SERVICES PAGES */

.onlymobile {
  display: none
}

.onlydesktop {
  display: block;
}

.container_results_page {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  margin-bottom: 50px;
}

.currentsearchValues_container {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
  justify-content: space-between;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.container_results_page .formContainer {
  min-width: 250px;
  margin-right: 10px;
  max-width: 250px;
  background: var(--grey-light-color);
  border: 1px solid #B5B5B5;
  border-radius: 5px;
  padding-top: 20px;
  height: fit-content;
}

.container_results_page .formContainer h3 {
  margin: 0 0 20px 0;
  padding: 20px 10px 10px 10px;
  color: var(--blue-footer-color);
}

.container_results_page .formContainer label {
  color: var(--blue-footer-color);
}

.container_results_page .formContainer input {
  border-radius: 0;
}

.container_results_page .formContainer div {
  box-shadow: none !important;
  border-radius: 0 !important;
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}

.container_results_page .formContainer div.compact_custom_input span {
  border-radius: 0;
  width: 30px;
}

.container_results_page .formContainer input.compact_custom_input {
  width: calc(100% - 30px);
}

.form-preventivatore-compact-container {
  max-width: 300px;
  background: var(--grey-light-color);
}

.button-preventivatore-compact {
  color: #fff;
  text-transform: uppercase;
  background: var(--blue-light-color);
  margin: 0px 10px 20px 10px;
  width: calc(100% - 20px);
  font-size: 15px;
  padding: 10px 0;
}

.three-col-container {
  display: flex;
  max-width: 1200px;
  margin: 0 auto;
  justify-content: center;
}

.three-col {
  width: 33.3%;
  padding: 0 40px;
}

.three-col img.image-two-col {
  /* max-width: 250px; */
  text-align: center;
  margin: 0 auto 30px;
}

.three-col>p {
  text-align: center;
  /* max-width: 180px; */
  font-size: 28px;
  margin: 0 auto;
}

.three-col-double {
  width: 66.6%;
  max-width: 600px;
}

.three-col-single {
  width: 33.3%;
}

.three-col-single .default-title {
  margin: 0;
  font-size: 40px;
}

.text-with-dots {
  padding: 40px 0px 40px 40px;
}

.text-with-dots>p {
  position: relative;
  padding-left: 70px;
  margin-left: 30px;
  border-left: solid 1px var(--blue-button);
  margin-bottom: 0;
  font-size: 22px;
  padding-bottom: 60px;
  margin-top: 0;
}

.text-with-dots>p:last-child {
  border-left: none;
}

.text-with-dots>p::after {
  position: absolute;
  background-image: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><g stroke-linecap="round" stroke-width="4" stroke="rgb(10, 146, 222)" fill="rgb(10, 146, 222)"><path d="M173.9 439.4l-166.4-166.4c-10-10-10-26.2 0-36.2l36.2-36.2c10-10 26.2-10 36.2 0L192 312.7 432.1 72.6c10-10 26.2-10 36.2 0l36.2 36.2c10 10 10 26.2 0 36.2l-294.4 294.4c-10 10-26.2 10-36.2 0z"/></g></svg>');
  background-size: cover;
  content:'';
  width: 23px;
  height: 23px;
  left: -11px;
  top: 15px;
}

.text-with-dots>p::before {
  position: absolute;
  content: "";
  display: inline-block;
  color: var(--blue-button);
  vertical-align: middle;
  font-weight: 900;
  left: -25px;
  top: 0px;
  border-radius: 50%;
  border: solid 1px var(--blue-button);
  width: 30px;
  height: 30px;
  padding: 10px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
}

.home-banks-carousel {
  padding: 10px 60px 60px;
  background-color: #F4F3F4;
}

.home-reviews-carousel {
  background-color: #F4F3F4;
  padding: 50px 60px;
}

.home-reviews-carousel .little-title {
  font-size: 40px;
}

.most-asked-mutual-container {
  padding: 0 15px 15px;
}

.most-asked-mutual-intro {
  font-size: 18px;
  color: #3a3a3a;
  margin-bottom: 15px;
}

.most-asked-mutual-card {
  background-color: #fff;
  box-shadow: 0px 0px 15px #00000040;
  border-radius: 10px;
  padding: 15px;
  font-size: 13px;
  color: #3a3a3a;
}

.most-asked-mutual-card-head {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  position: relative;
  /* min-height: 50px; */
}

img.most-asked-mutual-bank-image {
  width: auto;
  height: 30px;
  ;
  position: absolute;
  right: 0;
  top: 0;
}

.most-asked-mutual-installment {
  color: #0A92DE;
  font-size: 66px;
  line-height: 80px;
  font-weight: bold;
  letter-spacing: 1px;
}

.most-asked-mutual-card-footer {
  display: flex;
  font-size: 10px;
  margin: 20px 0;
  width: 100%;
}

.most-asked-mutual-card-footer-col {
  flex: 1;
  border-right: solid 1px #0a92de;
  margin-right: 20px;
  font-size: 15px;
}

.most-asked-mutual-duration {
  font-size: 20px;
}

.most-asked-mutual-card-footer-col:last-child {
  margin-right: 0;
  border-right: none;
}

.home-banks-carousel-button-container {
  display: flex;
  margin: 30px auto 0px;
  justify-content: center;
}

button.home-banks-carousel-button {
  padding: 8px 30px;
  text-align: center;
  text-transform: uppercase;
  font-size: 22px;
  color: var(--blue-light-color);
  border: solid 1px var(--blue-light-color);
  border-radius: 3px;
  transition: 0.5s;
  background-color: rgb(10 146 222 / 6%);
}

button.home-banks-carousel-button:hover {
  background-color: var(--blue-light-color);
  color: #fff;
}

#how-does-it-work {
  margin: 50px auto;
}

#advantages {
  margin: 50px auto;
}

.searchBuddy {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 13px;
  margin: 0 auto;
  margin-bottom: 20px
}

.searchBuddy .avatar {
  padding: 10px;
}

.searchBuddy.onlydesktop {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

.searchBuddy.onlymobile {
  display: none;
}

.payment-amount-desktop {
  display: contents;
}

.payment-amount-mobile {
  display: none;
}

.step-page-intro {
  /* background: #41BA58; */
  background: #0A92DE;
  color: #fff;
  text-transform: uppercase;
  font-weight: bold;
  line-height: 25px;
  font-size: 15px;
  display: flex;
  justify-content: center;
  padding: 0 20px;
  border-radius: 10px;
  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;
  width: 60%;
}


/* POPUP INFORMATIVA */
.popupContainer{
  margin: 0;
  position: fixed;
  top: -20px;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index:999999999;
}

.popupContainer > .popupBackground {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9;
  cursor: pointer;
}

.popupContainer > .popupContent{
  width: 80%;
  min-width: 300px;
  background: #fff;
  padding: 20px;
  max-height: 80vh;
  overflow: scroll;
  position: absolute;
  z-index: 999999;
}

.popupContainer>.popupContent .popupCloseButton{
  width:15px;
  height: 15px;
  position: absolute; 
  right: 10px;
  top: 10px;
  z-index: 99999999999;
  cursor:pointer;
}

/* INTRO RESULTS */
#cms-page .moduleResultsContainer{
  padding-top:20px;
}

#cms-page .moduleResultsContainer .introResults{
  font-size:0.7em;
  padding:20px;
  border:2px solid #3799d0;
  margin-bottom:10px;
  position:relative
}

#cms-page .moduleResultsContainer .introResults h2{
  font-size: 1.17em;
  margin:0;
}
#cms-page .moduleResultsContainer .introResults h3 {
  font-size: 1.17em;
  margin-bottom: 0;
}

#cms-page .moduleResultsContainer .introResults p {
  margin:0;
}

#cms-page .moduleResultsContainer .introResults .arrowContent {
  position: absolute;
  transform: rotate(45deg);
  left: -6px;
  background: #fff;
  width:10px;
  height: 10px;
}

#cms-page .moduleResultsContainer .introResults .arrowContent::before {
  content: '';
  width:10px;
  position: absolute;
  top:8px;
  left: 0;
  border-bottom:2px solid #3799d0;
}
#cms-page .moduleResultsContainer .introResults .arrowContent::after {

  content: '';
  height: 10px;
  position: absolute;
  top: 0px;
  left: 0;
  width: 10px;
  border-left: 2px solid #3799d0;

}

.sideBannerBlogPage{
  position: -webkit-sticky;
  position: sticky;
  top: 10px;
  max-height: max(90vh, 900px);
  background: var(--blue-footer-color);
  color: #fff;
  border-radius: 30px;
  padding: min(4vh, 40px);
  width: 100%;
}

@media (max-width: 1200px) {

  /* .estimator-list-head {
      display: none;
    } */
}

@media (max-width: 991px) {

  header {
    padding: 20px
  }

  .home-main-banner {
    background: #103C55 !important;
    padding-bottom: 60px;
  }

  .home-main-banner .two-col-container-b {
    background: #103C55 !important;
    padding: 0;
  }


  /* .main-banner {
        min-height: 100vh;
        display: flex;
        align-items: flex-end;
        background-size: 150% auto;
        background-repeat: no-repeat;
        background-position: center top;
        background-image: url('/mainbanner_home.jpeg');
        padding-top: 100px;
    } */

  .estimator-list-table {
    border: 0px;
  }


  .main-title {
    font-size: 32px;
  }

  .footer-container {
    flex-wrap: wrap;
  }

  .footer-text-container {
    width: 100%;
    margin-bottom: 30px;
  }

  .footer-block {
    flex: 1;
  }

  .header-menu-container {
    display: none;
  }

  /* .headerposition{
        display: none;
    } */

  .header-hamburger .hamburger-button {
    display: block;
    height: 100%;
  }

  .home-main-banner .two-col-container-b {
    padding-top: 130px;
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 20px;
  }

  .two-col {
    width: 100%;
    padding: 0;
  }

  .home-counter {
    width: 100%;
    max-width: 400px;
    margin: 0 auto 30px;
  }

  .two-input-container .input-container {
    width: 100%;
  }

  .two-input-container {
    display: block;
  }

  .two-input-container .input-container.right {
    margin-left: 0;
  }

  .contact-form-container {
    margin: 30px auto 0;
  }

  .estimator-list-button {
    /* width: 50%; */
    /* max-width: 200px; */
    margin: 0px auto 10px;
  }

  .formContainer{
    margin-bottom:30px;
  }

  .estimator-list-button.blue-button {
    width: 100%;
    margin-top: 0;
  }

  .header-link .header-submenu {
    padding: 10px;
  }

  .onlymobile {
    display: block
  }

  .onlydesktop {
    display: none !important;
  }

  .searchBuddy.onlymobile {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  }

  .container_results_page {
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;   
  }

  #cms-page .moduleResultsContainer .container_results_page{
    margin-bottom: 0px;
  }

  .form-preventivatore-compact-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: scroll;
    width: 100%;
    max-width: 100%;
    padding: 20px;
    display: none;
  }

  .container_results_page .formContainer {
    margin-right: 0;
    padding-top: 0;
    margin-bottom: 20px;
    max-width: 100%;
  }

  .form-preventivatore-compact-container.showForm {
    display: block;
  }

  .container_results_page .formContainer div.currentSearch {
    width: 100%;
    padding: 0 10px 10px 10px !important;
    background: var(--grey-light-color);
  }

  .container_results_page .formContainer h3 {
    margin: 0;
  }

  .currentSearch .currentsearchValues {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: flex-start;
    justify-content: flex-start;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0px 10px 0px 0;
  }

  .currentSearch .currentsearchValue {
    margin: 0;
    padding: 2px 0px 2px 10px;
    font-size: 13px;
    line-height: 26px;
    color: var(--blue-footer-color);
  }

  div.buttonShowFormClose,
  .form-preventivatore-compact-container div.buttonShowFormClose,
  .currentSearch div.buttonShowForm {
    background-color: rgba(49, 144, 203, 0.15);
    border-radius: 5px !important;
    max-width: 140px;
    color: var(--blue-light-color);
    border: 1px solid var(--blue-light-color);
    padding: 8px 20px !important;
    text-align: center;
    text-transform: uppercase;
    margin: 10px !important;
    font-size: 12px;
    cursor: pointer;
    font-weight: normal;
  }

  div.buttonShowFormClose:hover,
  .form-preventivatore-compact-container div.buttonShowFormClose:hover,
  .currentSearch div.buttonShowForm:hover {
    background-color: var(--blue-light-color);
    color: #fff;
  }

  .form-preventivatore-compact-container h3 {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: space-between;
    -ms-flex-pack: space-between;
    justify-content: space-between;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  /* RICHIEDI INFO POPUP */
  .popup-preventivatore .popup-content {
    /* height: 100vh; */
    width: 100vw;
    /* overflow:scroll; */
    /* border-radius: 0; */
    /* background: #fff !important; */
  }

  .popup-container.popup-preventivatore {
    padding-bottom: 0;
    padding-top: 0;
  }

  .popup-leftCol {
    border: 0;
    padding-top: 0;
    padding-bottom: 0;
    margin-bottom: 0;
  }

  .popup-rightCol {
    margin-top: 0;
    padding-top: 0;
    margin-bottom: 0;
    padding-bottom: 70px;
  }

  .popup-leftCol img.image-in-estimator-list {
    margin-bottom: 0;
    max-height: 35px;
    float: right;
  }

  .popup-preventivatore-form-container div {
    max-height: unset;
  }

  .popup-container .buttonShowFormClosePreventivo.onlymobile {
    margin-bottom: 20px;
  }

  .introForm {
    text-align: center;
    padding-top: 20px;
    font-size: 20px;
  }

  .introForm.introFormCustom {
    font-weight: 700;
    color: #103c55;
  }

  .popup-head {
    font-size: 16px;
    line-height: 16px;
    padding-top: 15px;
    padding-bottom: 15px;
    justify-content: center;
    justify-content: space-between;
  }

  
  .popup-preventivatore input {
    box-shadow: unset !important;
    border-radius: 0 !important;
  }


  .popup-preventivatore-form-container .input-checkbox {
    padding: 0 5px;
    margin-bottom: 10px;
  }

  .popup-preventivatore-form-container label {
    color: var(--blue-footer-color)
  }

  .popup-close-icon {
    display: none;
  }

  .three-col-container {
    display: block;
  }

  .three-col {
    width: 100%;
    margin-bottom: 50px;
  }

  #how-does-it-work .default-title {
    margin-bottom: 50px;
  }

  .three-col-double {
    width: 100%;
    text-align: center;
  }

  .three-col-single {
    width: 100%;
    margin: 0 auto;
  }

  .main-banner-col-image {
    display: none;
  }

  .text-with-dots>p {
    text-align: left;
  }


  .three-col>p {
    max-width: 300px;
    font-size: 15px;
  }

  .three-col img.image-two-col {
    max-width: 300px;
  }

  .default-title {
    text-align: center;
    padding: 20px;
  }

  header.blu-header-bg {
    background-color: #fff;
  }

  .home-main-banner .default-title {
    text-align: left;
    padding-left: 0;
    padding-bottom: 5px;
    ;
    margin: 0;
  }

  .home-reviews-carousel .little-title,
  .three-col-single .default-title,
  .default-title {
    padding-bottom: 5px;
    margin: 0;
    font-size: 30px;
  }

  .home-main-banner .default-subtitle {
    margin-bottom: 20px;
  }

  .home-main-banner .home-mutual-description {
    margin-top: 5px;
  }

  .home-main-banner .two-col-container-b .default-title {
    width: 100%;
    max-width: 100%;
  }

  .home-main-banner .two-col-container-b {
    padding-top: 110px;
  }

  .header-container {
    margin: 0
  }

  .container-wider {
    width: calc(100% - 30px);
    margin-left: 15px;
    margin-right: 15px;
  }

  .text-with-dots {
    padding-bottom: 0;
  }

  #how-does-it-work {
    margin: 30px auto;
  }

  #how-does-it-work .default-title.text-center {
    padding: 0;
    margin: 0;
  }

  .most-asked-mutual-installment {
    font-size: 46px;
  }
}

@media (max-width: 767px) {

  .main-title {
    font-size: 25px;
    margin-bottom: -10px;
  }

  .container-wider {
    width: calc(100% - 30px);
    margin-left: 15px;
    margin-right: 15px;
  }

  footer {
    padding: 50px 0;
  }

  .footer-block {
    flex: none;
    width: 100%;
    margin-bottom: 30px;
  }

  .footer-block:last-child {
    margin-bottom: 0;
  }

  .onlymobile {
    display: block
  }

  .searchBuddy.onlymobile {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  }

  .onlydesktop {
    display: none;
  }

  .main-banner-text .default-title {
    font-size: 25px;
    line-height: 35px;
  }

  .main-banner-text .default-subtitle {
    font-size: 20px;
    line-height: 30px;
  }

  
  .home-mutual-description {
    font-size: 15px;
  }

  button.home-mutual-input-button {
    /* font-size: 12px; */
    /* padding: 5px 20px; */
    padding: 7px 15px;
    text-align: left;
    font-size: 16px;
    font-weight: 600;
  }

  img.most-asked-mutual-bank-image {
    position: initial;
  }

  button.home-banks-carousel-button {
    padding: 5px 20px;
  }

  .text-with-dots {
    padding-left: 0;
  }

}

@media (max-width: 575px) {

  .main-title {
    font-size: 20px;
  }

  .onlymobile {
    display: block
  }

  .searchBuddy.onlymobile {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  }

  .onlydesktop {
    display: none;
  }

  .text-with-dots>p {
    padding-left: 50px;
    border-width: 3px;
  }

  .text-with-dots>p::before {
    border-width: 3px;
    left: -29px;
  }

  .tp-widget-wrapper {
    width: 278px !important;
    flex-direction: column;
    font-size:13px !important;
  }

  .home-main-banner .two-col-container-b .default-title {
    padding-top: 9px;
    margin-top: 0px;
  }

  .toc-label {
    font-size: 12px;
    font-weight: 200;
  }

  .step-page-intro {
    width: calc(100% - 50px);
    padding: 0 5px;
    text-align: center;
    font-size: 14px;
  }

}

@media (max-width: 500px) {

  .default-title {
    font-size: 30px;
    margin-bottom: 0;
  }

  .default-subtitle {
    font-size: 20px;
  }

  .estimator-list-container .default-title {
    padding-bottom: 30px;
  }

  .estimator-list-button.blue-button {
    margin-top: 15px;
  }

  .text-container .default-title {
    font-size: 28px;
  }

  .text-container .title-subline {
    font-size: 20px;
    padding-top: 20px;
  }

  .most-asked-mutual-container {
    margin-top: 30px;
  }

  .home-banks-carousel {
    padding: 10px 30px 40px;
  }

  .home-reviews-carousel {
    padding: 50px 30px;
  }

  img.most-asked-mutual-bank-image {
    width: 80px;
    height: auto;
  }

  .most-asked-mutual-card-footer-col {
    font-size: 9px;
  }

  img.image-in-estimator-list {
    max-width: 150px;
  }
}

@media(max-width: 400px) {
  .payment-amount-desktop {
    display: none;
  }

  .payment-amount-mobile {
    display: contents;
  }
}

@media(max-width: 375px) {
  img.image-in-estimator-list {
    max-width: 100px;
  }
}

@media (max-width: 350px) {

  button.home-banks-carousel-button {
    font-size: 12px;
    padding: 5px 20px;
  }
}

.customselect-tipologialavoro,
.customselect-citta {
  border-radius: 0;
  border: 1px solid #707070;
  box-shadow: none;
  text-align: left;
}

@media (max-width: 991px) {

  .customselect-tipologialavoro,
  .customselect-citta {
    border: none;
  }
}

.lead-form-container .select-prefix-container {
  top: 35px;
}


@media (max-width: 990px) {
  .popup-container.popup-preventivatore.page-form .popup-content{
    margin-top:0
  }
  svg.phoneIconHeader{
    width: 0.6em !important;
    margin-right: 5px !important;
  }
  #cms-page .moduleResultsContainer .introResults .arrowContent {
    position: absolute;
    transform: rotate(135deg);
    left: calc(100% - 70px);
    top: -6px;
    background: #fff;
    width: 10px;
    height: 10px;
  }
}
@media (max-width: 768px) {
  .popup-container.popup-preventivatore.page-form .popup-content {
    margin-top: 15px
  }
  .error-phone-container .popup-leftCol {
    text-align: center;
    margin: 10px
  }
  .error-phone-container .popup-leftCol > img {
    max-width: 70% !important;
  }
}

@media (max-width: 600px) {
  .popup-container.popup-preventivatore.page-form .popup-content {
    margin-top: 0px
  }
}

/* .grecaptcha-badge {
  visibility: hidden;
} */

.checkPhoneHide {
  display: none;
}

.checkPhoneHide+.popup-rightCol {
  padding: 0;
  margin-top: 0;
  margin-bottom: 0;
  width: 100%;
}

.popup-content.checkPhone {
  margin-bottom: 0 !important;
  border: 0;
}

.checkPhoneHide+.error-phone-container {
  margin-top: 18px !important;
  margin: 0 auto;
}

@media (max-width: 1599px) {
  .checkPhoneHide+.error-phone-container {
    width: calc(100vw - 30px) !important;
  }
}

@media (min-width: 769px) {
  .checkPhoneHide+.error-phone-container {
    margin-top: 0 !important;
  }
  .popup-content.checkPhone {
    padding-top: 0;
    margin-bottom: 10px !important;
    width: calc(100vw - 30px) !important;
  }
  .error-phone-container .popup-leftCol {
    text-align: center;
  }
  .error-phone-container .popup-leftCol > img {
    max-width: 80% !important;
  }
}

@media (min-width: 1599px) {
  .popup-content.checkPhone {
    width: 1500px !important;
    max-width: 1500px !important;
    margin-top: 90px !important;
    margin-bottom: 50px !important;
  }
}

.cookieRow .cookieCol {
  padding: 5px;
  margin: 1px;
  min-width:200px;
  font-size:14px;
}

.cookieRow:nth-child(even) .cookieCol {
  background: #e0e0e0;
}
.cookieRow:nth-child(odd) .cookieCol{
  background:#f0f0f0;
}

.cookieRow.headRow .cookieCol{
  background:#103C55;
  color:#fff;
}

.tableContainer{
  max-width: 100%;
  overflow:scroll;
}

#cms-page  {
    font-size: clamp(1.125rem, 0.971rem + 0.493vw, 1.5625rem);
    color: #3A3A3A;
}

#cms-page h2 {
    font-size: clamp(1.5rem, 0.9278rem + 1.831vw, 3.125rem);
    margin: clamp(1.5625rem, 0.3521rem + 3.8732vw, 5rem) 0;
}

#cms-page a {
    text-decoration: underline;
}

/* styles from wp */


:root {
    --wp-admin-theme-color: #007cba;
    --wp-admin-theme-color--rgb: 0, 124, 186;
    --wp-admin-theme-color-darker-10: #006ba1;
    --wp-admin-theme-color-darker-10--rgb: 0, 107, 161;
    --wp-admin-theme-color-darker-20: #005a87;
    --wp-admin-theme-color-darker-20--rgb: 0, 90, 135;
    --wp-admin-border-width-focus: 2px;
    --wp-block-synced-color: #7a00df;
    --wp-block-synced-color--rgb: 122, 0, 223
}

@media (min-resolution: 192dpi) {
    :root {
        --wp-admin-border-width-focus: 1.5px
    }
}

:root {
    --wp--preset--font-size--normal: 16px;
    --wp--preset--font-size--huge: 42px
}

html :where(.has-border-color) {
    border-style: solid
}

html :where([style*=border-top-color]) {
    border-top-style: solid
}

html :where([style*=border-right-color]) {
    border-right-style: solid
}

html :where([style*=border-bottom-color]) {
    border-bottom-style: solid
}

html :where([style*=border-left-color]) {
    border-left-style: solid
}

html :where([style*=border-width]) {
    border-style: solid
}

html :where([style*=border-top-width]) {
    border-top-style: solid
}

html :where([style*=border-right-width]) {
    border-right-style: solid
}

html :where([style*=border-bottom-width]) {
    border-bottom-style: solid
}

html :where([style*=border-left-width]) {
    border-left-style: solid
}

html :where(img[class*=wp-image-]) {
    height: auto;
    max-width: 100%
}

html :where(.is-position-sticky) {
    --wp-admin--admin-bar--position-offset: var(--wp-admin--admin-bar--height, 0px)
}

@media screen and (max-width: 600px) {
    html :where(.is-position-sticky) {
        --wp-admin--admin-bar--position-offset: 0px
    }
}

@keyframes turn-on-visibility {
    0% {
        opacity: 0
    }

    to {
        opacity: 1
    }
}

@keyframes turn-off-visibility {
    0% {
        opacity: 1;
        visibility: visible
    }

    99% {
        opacity: 0;
        visibility: visible
    }

    to {
        opacity: 0;
        visibility: hidden
    }
}

@keyframes lightbox-zoom-in {
    0% {
        transform: translate(calc(-50vw + var(--wp--lightbox-initial-left-position)), calc(-50vh + var(--wp--lightbox-initial-top-position))) scale(var(--wp--lightbox-scale))
    }

    to {
        transform: translate(-50%, -50%) scale(1)
    }
}

@keyframes lightbox-zoom-out {
    0% {
        transform: translate(-50%, -50%) scale(1);
        visibility: visible
    }

    99% {
        visibility: visible
    }

    to {
        transform: translate(calc(-50vw + var(--wp--lightbox-initial-left-position)), calc(-50vh + var(--wp--lightbox-initial-top-position))) scale(var(--wp--lightbox-scale));
        visibility: hidden
    }
}


